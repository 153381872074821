// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-category-jsx": () => import("/opt/build/repo/src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-page-jsx": () => import("/opt/build/repo/src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-thankyou-jsx": () => import("/opt/build/repo/src/pages/contact/thankyou.jsx" /* webpackChunkName: "component---src-pages-contact-thankyou-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preview-jsx": () => import("/opt/build/repo/src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */)
}

